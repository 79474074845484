import ContextualDisplayer from './cookies-manager/ContextualDisplayer';

// https://developers.axeptio.eu/v/francais/integration/consentement-contextuel

// eslint-disable-next-line no-undef, no-underscore-dangle
(window._axcb = window._axcb || []).push((sdk) => {
  sdk.on('cookies:complete', (choices) => {
    const event = new CustomEvent('cookies:updateChoice', {
      detail: choices,
    });
    document.dispatchEvent(event);
  });
});

const $holders = document.querySelectorAll('.js-axeptio-contextual-holder');

if ($holders.length) {
  $holders.forEach(($holder) => {
    const displayer = new ContextualDisplayer($holder, $holder.getAttribute('data-service'));
  });
}
